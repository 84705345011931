import {
  FooterContainer,
  FooterWrapper,
  FooterText,
  GithubLink,
  TextWrapper,
  LinkedinLink,
} from "./Footer.styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <TextWrapper>
          <FooterText>
            © {new Date().getFullYear()} by{" "}
            <GithubLink href="https://github.com/GrowthVVork/" target="_blank">
              GrowthVVork
            </GithubLink>
          </FooterText>
        </TextWrapper>
        <FooterText>
          This website is maintained by{" "}
          <LinkedinLink href="https://www.linkedin.com/in/tejaskothari03/" target="_blank">
          Tejas
          </LinkedinLink>
          ,{" "}
          <LinkedinLink href="https://www.linkedin.com/in/gsiddhi/" target="_blank">
          Siddhi
          </LinkedinLink>
          {" "} &{" "}
          <LinkedinLink href="https://www.linkedin.com/in/sreshtha-rawat/" target="_blank">
          Sreshtha
          </LinkedinLink>
          </FooterText>
          </FooterWrapper>
          </FooterContainer>
          );
};
